(function() {
  const menuContainer = document.getElementById('navigation')
  const titleWrapper = menuContainer.querySelector('.main-title-wrapper')
  const menuToggleButton = menuContainer.querySelector('.menu-burger')
  const menuWrapper = menuContainer.querySelector('.menu')

  const menuStateClassNames = {
    open: 'menu-mobile-open',
    closed: 'menu-mobile-closed'
  }

  function toggleMenu() {
    if (menuWrapper.classList.contains(menuStateClassNames.open)) {
      menuWrapper.classList.remove(menuStateClassNames.open)
      menuWrapper.classList.add(menuStateClassNames.closed)
    } else {
      menuWrapper.classList.add(menuStateClassNames.open)
      menuWrapper.classList.remove(menuStateClassNames.closed)
    }
  }

  if ('ontouchstart' in document.documentElement) {
    titleWrapper.addEventListener('touchend', toggleMenu)
    menuToggleButton.addEventListener('touchend', toggleMenu)
  } else {
    titleWrapper.addEventListener('click', toggleMenu)
  }
})();
