(function () {
  const effectWrapper = document.querySelector('.main-image-wrapper .main-image-effect-wrapper')
  const mainImage = document.querySelector('.main-image-wrapper .main-image')

  if (effectWrapper && mainImage) {
    const mainImageSize = effectWrapper.offsetHeight
    const mainImageBg = document.querySelector('.main-image-wrapper .main-image-background')

    const setScrollEffect = () => {

      // do this in animation loop
      // instead of randomly running when needed
      // for better performance
      // .. and not to kill macbooks
      window.requestAnimationFrame(() => {
        const scrolledRatio = window.scrollY / mainImageSize

        if (scrolledRatio <= 1) {
          const bgOpacitydRatio = window.scrollY / (mainImageSize / 3)

          const opacity = 1 - (scrolledRatio > 1 ? 1 : scrolledRatio)
          const bgOpacity = bgOpacitydRatio > 1 ? 1 : bgOpacitydRatio
          const zoomRatio = 1 + ((1 - opacity) * 0.05)

          mainImage.style.opacity = opacity
          mainImage.style.transform = `scale3d(${zoomRatio}, ${zoomRatio}, 1)`
          mainImage.style['-webkit-transform'] = `scale3d(${zoomRatio}, ${zoomRatio}, 1)`
          mainImage.style.transition = `0.1s ease-out`

          mainImageBg.style.opacity = bgOpacity
          mainImageBg.style.transition = `0.1s ease-out`
        }
      })
    }

    window.addEventListener('scroll', setScrollEffect)
    window.addEventListener('resize', setScrollEffect)

    setScrollEffect()
  }

})()
